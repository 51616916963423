import {
  symOutlinedEdit,
  symOutlinedDelete,
  symOutlinedUpload,
  symOutlinedClose,
  symOutlinedCancel,
  symOutlinedFolderCopy,
  symOutlinedAccountTree,
  symOutlinedStorefront,
  symOutlinedHelp,
  symOutlinedKeyboardArrowLeft,
  symOutlinedKeyboardArrowRight,
  symOutlinedArrowForward,
  symOutlinedNotifications,
  symOutlinedNotificationsUnread,
  symOutlinedLogout,
  symOutlinedKey,
  symOutlinedSettings,
  symOutlinedContentCopy,
  symOutlinedPersonAdd,
  symOutlinedRestartAlt,
  symOutlinedWarning,
  symOutlinedPersonOff,
  symOutlinedPersonCheck,
  symOutlinedBlock,
  symOutlinedForwardToInbox,
  symOutlinedInbox,
  symOutlinedFilterAlt,
  symOutlinedFolderOpen,
  symOutlinedSearch,
  symOutlinedPerson,
  symOutlinedInfo,
  symOutlinedDescription,
  symOutlinedMenuBook,
  symOutlinedArrowBack,
  symOutlinedVerifiedUser,
  symOutlinedArticle,
  symOutlinedAccountCircle,
  symOutlinedCalendarToday,
  symOutlinedDeployedCode,
  symOutlinedCheckCircle,
  symOutlinedSchedule,
  symOutlinedInventory2,
  symOutlinedTimelapse,
  symOutlinedPageInfo,
  symOutlinedBeenhere,
  symOutlinedShoppingmode,
  symOutlinedKeyboardArrowUp,
  symOutlinedKeyboardArrowDown,
  symOutlinedAcUnit,
  symOutlinedLock,
  symOutlinedSyncAlt,
  symOutlinedTask,
  symOutlinedChat,
  symOutlinedBarChart4Bars,
  symOutlinedVisibility,
  symOutlinedCheckBox,
  symOutlinedMoreVert,
  symOutlinedAddCircle,
  symOutlinedDownload,
  symOutlinedCheck,
  symOutlinedEventBusy,
  symOutlinedFormatH1,
  symOutlinedFormatBold,
  symOutlinedFormatItalic,
  symOutlinedAddLink,
  symOutlinedFormatListBulleted,
  symOutlinedFormatListNumbered,
  symOutlinedChecklist,
  symOutlinedFormatAlignCenter,
  symOutlinedFormatAlignRight,
  symOutlinedFormatAlignLeft,
  symOutlinedNorth,
  symOutlinedSouth,
  symOutlinedAssignment,
  symOutlinedKeyboardDoubleArrowLeft,
  symOutlinedKeyboardDoubleArrowRight,
  symOutlinedUndo,
  symOutlinedFilePresent,
  symOutlinedOpenInNew,
  symOutlinedRefresh,
  symOutlinedError,
} from '@quasar/extras/material-symbols-outlined';

const editOutlined = symOutlinedEdit;
const deleteOutlined = symOutlinedDelete;
const uploadOutlined = symOutlinedUpload;
const closeOutlined = symOutlinedClose;
const cancelOutlined = symOutlinedCancel;
const folderCopyOutlined = symOutlinedFolderCopy;
const accountTreeOutlined = symOutlinedAccountTree;
const storeOutlined = symOutlinedStorefront;
const helpOutlined = symOutlinedHelp;
const leftArrowOutlined = symOutlinedKeyboardArrowLeft;
const rightArrowOutlined = symOutlinedKeyboardArrowRight;
const forwardArrowOutlined = symOutlinedArrowForward;
const notificationsOutlined = symOutlinedNotifications;
const notificationsUnreadOutlined = symOutlinedNotificationsUnread;
const logoutOutlined = symOutlinedLogout;
const keyOutlined = symOutlinedKey;
const settingsOutlined = symOutlinedSettings;
const contentCopyOutlined = symOutlinedContentCopy;
const personAddOutlined = symOutlinedPersonAdd;
const restartAltOutlined = symOutlinedRestartAlt;
const warningOutlined = symOutlinedWarning;
const personOffOutlined = symOutlinedPersonOff;
const personCheckOutlined = symOutlinedPersonCheck;
const blockOutlined = symOutlinedBlock;
const forwardToInboxOutlined = symOutlinedForwardToInbox;
const inboxOutlined = symOutlinedInbox;
const filterAltOutlined = symOutlinedFilterAlt;
const folderOpenOutlined = symOutlinedFolderOpen;
const searchOutlined = symOutlinedSearch;
const personOutlined = symOutlinedPerson;
const infoOutlined = symOutlinedInfo;
const descriptionOutlined = symOutlinedDescription;
const menuBookOutlined = symOutlinedMenuBook;
const arrowBackOutlined = symOutlinedArrowBack;
const verifiedUserOutlined = symOutlinedVerifiedUser;
const articleOutlined = symOutlinedArticle;
const accountCircleOutlined = symOutlinedAccountCircle;
const calendarTodayOutlined = symOutlinedCalendarToday;
const deployedCodeOutlined = symOutlinedDeployedCode;
const checkCircleOutlined = symOutlinedCheckCircle;
const scheduleOutlined = symOutlinedSchedule;
const inventory2Outlined = symOutlinedInventory2;
const clockLoader60Outlined = symOutlinedTimelapse;
const pageInfoOutlined = symOutlinedPageInfo;
const beenhereOutlined = symOutlinedBeenhere;
const shoppingmodeOutlined = symOutlinedShoppingmode;
const keyboardArrowUpOutlined = symOutlinedKeyboardArrowUp;
const keyboardArrowDownOutlined = symOutlinedKeyboardArrowDown;
const acUnitOutlined = symOutlinedAcUnit;
const lockOutlined = symOutlinedLock;
const syncAltOutlined = symOutlinedSyncAlt;
const taskOutlined = symOutlinedTask;
const chatOutlined = symOutlinedChat;
const barChartOutlined = symOutlinedBarChart4Bars;
const visibilityOutlined = symOutlinedVisibility;
const checkBoxOutlined = symOutlinedCheckBox;
const moreVertOutlined = symOutlinedMoreVert;
const addCircleOutlined = symOutlinedAddCircle;
const downloadOutlined = symOutlinedDownload;
const checkOutlined = symOutlinedCheck;
const eventBusyOutlined = symOutlinedEventBusy;
const formatH1Outlined = symOutlinedFormatH1;
const formatBoldOutlined = symOutlinedFormatBold;
const formatItalicOutlined = symOutlinedFormatItalic;
const addLinkOutlined = symOutlinedAddLink;
const formatListBulletedOutlined = symOutlinedFormatListBulleted;
const formatListNumberedOutlined = symOutlinedFormatListNumbered;
const checklistOutlined = symOutlinedChecklist;
const formatAlignCenterOutlined = symOutlinedFormatAlignCenter;
const formatAlignRightOutlined = symOutlinedFormatAlignRight;
const formatAlignLeftOutlined = symOutlinedFormatAlignLeft;
const northOutlined = symOutlinedNorth;
const southOutlined = symOutlinedSouth;
const assignmentOutlined = symOutlinedAssignment;
const keyboardDoubleArrowLeftOutlined = symOutlinedKeyboardDoubleArrowLeft;
const keyboardDoubleArrowRightOutlined = symOutlinedKeyboardDoubleArrowRight;
const undoOutlined = symOutlinedUndo;
const filePresentOutlined = symOutlinedFilePresent;
const openInNewOutlined = symOutlinedOpenInNew;
const refreshOutlined = symOutlinedRefresh;
const errorOutlined = symOutlinedError;

export {
  editOutlined,
  deleteOutlined,
  uploadOutlined,
  closeOutlined,
  cancelOutlined,
  folderCopyOutlined,
  accountTreeOutlined,
  storeOutlined,
  helpOutlined,
  leftArrowOutlined,
  rightArrowOutlined,
  notificationsOutlined,
  notificationsUnreadOutlined,
  logoutOutlined,
  keyOutlined,
  settingsOutlined,
  contentCopyOutlined,
  personAddOutlined,
  restartAltOutlined,
  warningOutlined,
  personOffOutlined,
  personCheckOutlined,
  blockOutlined,
  forwardToInboxOutlined,
  inboxOutlined,
  filterAltOutlined,
  folderOpenOutlined,
  searchOutlined,
  personOutlined,
  infoOutlined,
  descriptionOutlined,
  menuBookOutlined,
  arrowBackOutlined,
  verifiedUserOutlined,
  articleOutlined,
  accountCircleOutlined,
  calendarTodayOutlined,
  deployedCodeOutlined,
  checkCircleOutlined,
  scheduleOutlined,
  clockLoader60Outlined,
  inventory2Outlined,
  pageInfoOutlined,
  beenhereOutlined,
  shoppingmodeOutlined,
  keyboardArrowUpOutlined,
  keyboardArrowDownOutlined,
  acUnitOutlined,
  lockOutlined,
  forwardArrowOutlined,
  syncAltOutlined,
  taskOutlined,
  chatOutlined,
  barChartOutlined,
  visibilityOutlined,
  checkBoxOutlined,
  moreVertOutlined,
  addCircleOutlined,
  downloadOutlined,
  checkOutlined,
  eventBusyOutlined,
  formatBoldOutlined,
  formatItalicOutlined,
  addLinkOutlined,
  formatListBulletedOutlined,
  formatListNumberedOutlined,
  checklistOutlined,
  formatAlignLeftOutlined,
  formatAlignCenterOutlined,
  formatAlignRightOutlined,
  northOutlined,
  southOutlined,
  assignmentOutlined,
  keyboardDoubleArrowLeftOutlined,
  keyboardDoubleArrowRightOutlined,
  undoOutlined,
  filePresentOutlined,
  openInNewOutlined,
  formatH1Outlined,
  refreshOutlined,
  errorOutlined,
};
